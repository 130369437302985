import { ChangeEventHandler, FocusEventHandler, SyntheticEvent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { Form, Input } from 'antd';
import { LabelWithToolTip } from '../label-with-tooltip/label-with-tooltip';
import { InputProps } from 'antd/lib/input';
import { parseEmptyValueAs } from '../../../../logic/formaters/formaters';
import RichTextEditor from '../rich-text-editor-input/rich-text-editor-input';
import { isNil } from 'lodash';
const { TextArea } = Input;

interface OwnProps extends InputProps {
  isPassword?: boolean;
  toolTipMessage?: string;
  rows?: number;
  onBlurFormater?: <T>(value: T) => T;
  richText: boolean;
}

type FormTextInputProps = WrappedFieldProps & FormItemProps & OwnProps;

export const FormTextInput = ({
  label,
  required,
  disabled,
  validateStatus,
  labelCol,
  wrapperCol,
  isPassword = false,
  toolTipMessage,
  onBlurFormater,
  meta,
  input,
  rows,
  richText = false,
  ...rest
}: FormTextInputProps) => {
  const errorMessage = !!meta && meta.error;
  const showError = !!meta && meta.touched && !!meta.error;
  const TextInputComponent = isPassword ? Input.Password : Input;
  const labelComponent = toolTipMessage ? <LabelWithToolTip label={label} toolTipMessage={toolTipMessage} /> : label;
  const isTextArea = !!rows && rows > 1;
  const { onBlur, onChange, ...restInput } = input;

  const isPasted = (e: SyntheticEvent<Element, InputEvent>): boolean =>
    !isNil(e.nativeEvent.inputType) && e.nativeEvent.inputType === 'insertFromPaste';

  const formatInputValue = (value: any) => {
    const formatedValue = onBlurFormater ? onBlurFormater(value) : value;
    return parseEmptyValueAs(null)(formatedValue);
  };

  const handleBlur: FocusEventHandler<any> = (e) => {
    const formatedValue = formatInputValue(e.target.value);

    onBlur(formatedValue);
  };

  const handleChange: ChangeEventHandler<any> = (e) => {
    const isPaste = isPasted(e as SyntheticEvent<Element, InputEvent>);
    const formatedValue = isPaste ? formatInputValue(e.target.value) : parseEmptyValueAs(null)(e.target.value);

    onChange(formatedValue);
  };

  const renderTextInput = () => {
    if (richText) {
      return <RichTextEditor input={input} meta={meta} />;
    }

    if (isTextArea) {
      return (
        <TextArea
          onBlur={handleBlur}
          onChange={handleChange}
          {...restInput}
          placeholder={rest.placeholder}
          disabled={disabled}
          rows={rows}
          autoComplete="off"
        />
      );
    }

    return (
      <TextInputComponent
        onBlur={handleBlur}
        onChange={handleChange}
        {...restInput}
        {...rest}
        disabled={disabled}
        autoComplete="off"
      />
    );
  };

  return (
    <Form.Item
      label={labelComponent}
      required={required}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? errorMessage : undefined}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      {renderTextInput()}
    </Form.Item>
  );
};
